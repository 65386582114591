@font-face {
    font-family: "Nexa";
    src: url(./fonts/Nexa-ExtraLight.ttf);
}

@font-face {
    font-family: "Nexa";
    font-weight: bold;
    src: url(./fonts/Nexa-Heavy.ttf);
}

:root {
    --background: #000000;
    --primary: #009FE3;
    --secondary: #047eb3;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Nexa", sans-serif;
    scroll-behavior: smooth;
}

body {
    background-color: var(--background);
    color: #FFFFFF;
}

.space-80 {
    height: 80px;
}

section {
    padding: 0 10vw;
}

h2 {
    position: relative;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;

    &::before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100px;
        height: 7px;
        border-radius: 100px;
        background-color: var(--primary);
    }
}

h3 {
    font-size: 32px;
    text-transform: uppercase;
    color: var(--primary);
}

p {
    font-size: 18px;
    margin-bottom: 10px;
}

.popup {
    position: absolute;
    z-index: 100000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    .popup-content {
        position: relative;
        background-color: #FFF;
        width: 30vw;
        padding: 7vw 20px 20px;
        border-radius: 5px;
        text-align: center;
        .img-box {
            position: absolute;
            top: -5vw;
            left: 10vw;
            width: 10vw;
            height: 10vw;
            background-color: #FFF;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 35%;
            }
            .loader-import {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                .circle {
                    border: 8px solid #FFF;
                    border-top: 8px solid var(--primary);
                    border-radius: 50%;
                    width: 60px;
                    height: 60px;
                    animation: spin 1s linear infinite;
                }
            }
        }
        .title {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: bold;
            color: var(--primary);
        }
        p {
            margin-bottom: 10px;
            &.message {
                margin-bottom: 5px;
            }
        }
        button {
            margin: 20px auto;
            &.btn {
                padding: 15px 30px;
                border: none;
                border-radius: 100px;
                background-color: var(--secondary);
                font-size: 18px;
                font-weight: 500;
                color: #FFF;
                transition: 0.3s;
                &:hover {
                    background-color: var(--orange-btn-home-hover);
                    cursor: pointer;
                }
            }
        }
    }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}