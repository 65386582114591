.admin {
    .background-fixed {
        background-image: url(../../img/jpg/background-backoffice.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
    }
    .content {
        padding: 30px;
        .space-30 {
            height: 30px;
        }
        .title {
            color: #000;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 30px;
        }
        .admin-grid-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
            justify-content: center;
            width: 100%;
        }
        .admin-column-container {
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 100%;
        }
        .btn-logout {
            background-color: red;
            display: flex;
            justify-content: center;
            padding: 10px 20px;
            border-radius: 8px;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                background-color: rgb(254, 65, 65);
            }
        }
}
}