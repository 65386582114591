.add-form {
    background-color: #F1F1F1;
    border-radius: 8px;
    padding: 30px;
    form {
        color: #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        #fileRealisation,
        #fileActualite {
            display: none;
        }
        label {
            width: 60%;
            padding: 25% 0;
            border: 1px solid #CCC;
            border-radius: 8px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: #777;
            background-color: #FFF;
            transition: 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                cursor: pointer;
                color: var(--primary);
                border: 1px solid var(--primary);
            }
        }
        input {
            width: 60%;
            height: 40px;
            background-color: #FFF;
            border: 1px solid #CCC;
            border-radius: 8px;
            outline: none;
            padding: 15px;
            color: #777;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;
            &:hover,
            &:focus,
            &:valid {
                border: 1px solid var(--primary);
            }
            &::placeholder {
                color: #777;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }
        button {
            width: 30%;
            height: 40px;
            background-color: var(--primary);
            color: #F1F1F1;
            font-size: 14px;
            text-transform: uppercase;
            border: none;
            border-radius: 8px;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                background-color: var(--secondary);
            }
        }
    }
}