section.lastwork {
    padding: 0;
    .header {
        margin-bottom: 50px;
        padding: 0 10vw;
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
        .grid-container {
            display: flex;
            flex-direction: column;
        }
    }

    @media (max-width: 768px) {
        
    }

    @media (max-width: 480px) {
        
    }

    @media (max-width: 320px) {
        
    }
}