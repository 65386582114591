.primary {
    padding: 12px 24px;
    border: 2px solid var(--primary);
    border-radius: 100px;
    color: #FFFFFF;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.3s;
    img {
        margin-left: 10px;
        height: 10px;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        transition: 0.3s;
    }
    &:hover {
        cursor: pointer;
        background-color: var(--primary);
    }
    &.simple {
        img {
            display: none;
        }
    }
    &.center {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.secondary {
    padding: 12px 24px;
    border: 2px solid var(--primary);
    border-radius: 100px;
    color: #000000;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.3s;
    img {
        margin-left: 10px;
        height: 10px;
        transition: 0.3s;
    }
    &:hover {
        cursor: pointer;
        background-color: var(--primary);
        color: #FFFFFF;
        img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
    }
    &.simple {
        img {
            display: none;
        }
    }
    &.center {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}