.edit-list {
    background-color: #F1F1F1;
    border-radius: 8px;
    padding: 30px;
    color: #000;
    .lines {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .line {
            width: 100%;
            padding: 10px 20px;
            background-color: #FFF;
            border: 1px solid #CCC;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
                background-color: #EEE;
                border: 1px solid var(--primary);
            }
            .left {
                display: flex;
                align-items: center;
                gap: 20px;
                .img-box {
                    width: 40px;
                    height: 40px;
                    border-radius: 100px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    img {
                        height: 100%;
                    }
                }
                .type-car {
                    text-transform: uppercase;
                }
            }
            .right {
                display: flex;
                align-items: center;
                gap: 20px;
                .btn-modify,
                .btn-delete {
                    padding: 3px 15px;
                    border-radius: 100px;
                    color: white;
                    background-color: #EAB009;
                    transition: 0.3s;
                    &:hover {
                        cursor: pointer;
                        background-color: #f0bf2d;
                    }
                }
                .btn-delete {
                    background-color: red;
                    &:hover {
                        background-color: rgb(254, 65, 65);
                    }
                }
            }
        }
    }
}