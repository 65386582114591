header.header-home {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-image: url(../../img/jpg/ford-fiesta.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    overflow: hidden;

    .background-video {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        object-position: 50% 70%;
    }

    .title {
        position: absolute;
        bottom: 75px;
        left: 50%;
        transform: translateX(-50%); // Centrage horizontal
        width: 90vw;
        max-width: 600px; // Limite la largeur maximale
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-size: 4vw;
            text-transform: uppercase;
            color: #FFF;
            text-align: center;
        }

        .logo-box {
            width: 50%;
            padding-top: 20px;
            img {
                width: 100%;
            }
        }

        .img-box {
            margin-top: 30px;
            width: 10%;
            img {
                width: 100%;
            }
            animation: move 2s linear infinite;
        }

        @keyframes move {
            0% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(30px);
            }
            100% {
                transform: translateY(0);
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50vh;
        background: linear-gradient(to top, #000 40%, rgba(0, 0, 0, 0));
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
        height: 100vh;
        overflow: hidden;
        .title {
            bottom: 50px;
            h1 {
                font-size: 5vw;
                line-height: 10vw;
            }
            .img-box {
                width: 8%;
            }
        }
    }

    @media (max-width: 768px) {
        height: 100vh;
        overflow: hidden;
        .title {
            bottom: 40px;
            h1 {
                font-size: 6vw;
                line-height: 12vw;
            }
            .img-box {
                width: 12%;
            }
        }
    }

    @media (max-width: 480px) {
        height: 100vh;
        overflow: hidden;
        .title {
            bottom: 20px;
            h1 {
                font-size: 7vw;
                line-height: 14vw;
            }
            .img-box {
                width: 15%;
            }
        }
    }

    @media (max-width: 320px) {
        height: 100vh;
        overflow: hidden;
        .title {
            bottom: 15px;
            h1 {
                font-size: 8vw;
                line-height: 16vw;
            }
            .img-box {
                width: 20%;
            }
        }
    }
}