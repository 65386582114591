.slider {
    margin-top: 50px;
    display: flex;
    gap: 80px;
    .left {
        position: relative;
        width: 50%;
        height: 400px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        .arrow-left,
        .arrow-right {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 50px;
            height: 50px;
            background-color: rgba($color: #000000, $alpha: 0.4);
            transition: 0.3s;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            &:hover {
                cursor: pointer;
                background-color: rgba($color: #000000, $alpha: 0.6);
            }
            img {
                width: 50%;
                height: 50%;
            }
        }
        .arrow-right {
            left: 50px;
        }
    }
    .right {
        width: 50%;
        margin: auto;
        p {
            margin: 20px 0 30px;
        }
    }

        // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
        flex-direction: column;
        .left {
            width: 100%;
        }
        .right {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        
    }

    @media (max-width: 480px) {
        
    }

    @media (max-width: 320px) {
        
    }
}
.slider-bullets {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    .bullet {
        height: 12px;
        width: 12px;
        border-radius: 100px;
        background-color: #D2D2D2;
        transition: 0.3s;
        &.active {
            background-color: var(--primary);
        }
        &:hover {
            cursor: pointer;
            background-color: var(--primary);
        }
    }
}