.modal {
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    &:hover {
        cursor: pointer;
    }
    .img-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50vw;
        height: 60vh;
        &:hover {
            cursor: default;
        }
        img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 8px;
        }
    }
}