section.prestations {
    display: flex;
    gap: 80px;
    .left {
        width: 50%;
        p {
            width: 100%;
            text-align: justify;
        }
    }
    .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        .img-box {
            position: relative;
            height: 250px;
            width: 350px;
            background-image: url(../../img/jpg/working.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: left;
            margin: 0 0 50px 50px;
            &::before {
                content: '';
                position: absolute;
                bottom: -50px;
                left: -50px;
                height: 250px;
                width: 350px;
                background-color: rgba($color: #009FE3, $alpha: 0.3);
                z-index: -1;
            }
        }
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
        flex-direction: column;
        .left {
            width: 100%;
        }
        .right {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        
    }

    @media (max-width: 480px) {
        
    }

    @media (max-width: 320px) {
        
    }
}