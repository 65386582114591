.admin-header {
    height: 60px;
    background-color: #F1F1F1;
    padding: 0 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
        filter: invert(100%);
        height: 30px;
    }

    p.user {
        color: #000;
        font-size: 16px;
        margin: 0;
        span {
            font-weight: bold;
        }
    }
}