.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    justify-content: center;
    width: 100vw;
    padding: 0 10vw;
    .grid-item {
        margin: 0 auto;
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
        grid-template-columns: repeat(1, 1fr);
    }

    @media (max-width: 768px) {
    }

    @media (max-width: 480px) {
    }

    @media (max-width: 320px) {
    }
}