.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../img/jpg/background-backoffice.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .container {
        padding: 60px;
        background-color: #F1F1F1;
        border-radius: 8px;
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
            color: #000;
            font-weight: bold;
            font-size: 36px;
            margin-bottom: 50px;
        }
        .btn-signin-admin {
            width: 100%;
            text-align: center;
            padding: 12px 24px;
            background-color: var(--primary);
            border-radius: 8px;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                background-color: var(--secondary);
            }
        }
        .error-message {
            width: 100%;
            color: red;
            text-align: center;
            padding: 12px 24px;
            background-color: rgba(255, 0, 0, 0.1);
            border: 1px solid red;
            border-radius: 8px;
            margin-top: 10px;
        }
    }
}