.contact-form {
    padding: 0 20vw;
    .form-container {
        width: 100%;
        margin: 5% 0;
        background-color: #FFFFFF;
        color: #000000;
        form {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding: 8%;
            .input-box {
                display: flex;
                flex-direction: column;
                label {
                    color: #000000;
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 12px;
                }
                .bot-line {
                    display: flex;
                    padding: 10px 0;
                    border-bottom: 1px solid #000000;
                    img {
                        height: 25px;
                        width: 25px;
                        margin-right: 15px;
                    }
                    input,
                    textarea {
                        border: none;
                        line-height: 25px;
                        font-size: 17px;
                        outline: none;
                        width: 100%;
                    }
                }
            }
            p.loading {
                padding: 12px 20px;
                background-color: #FFE5C6;
                border-bottom: 1px solid #FFA306;
                border-radius: 4px;
                color: #FFA306;
                font-weight: bold;

            }
            p.success {
                padding: 12px 20px;
                background-color: #B5EBCC;
                border-bottom: 1px solid #23B56A;
                border-radius: 4px;
                color: #23B56A;
                font-weight: bold;

            }
            p.error {
                padding: 12px 20px;
                background-color: #FFE3E0;
                border-bottom: 1px solid #FF5748;
                border-radius: 4px;
                color: #FF5748;
                font-weight: bold;

            }
        }
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
        padding: 10vw;
    }

    @media (max-width: 768px) {
    }

    @media (max-width: 480px) {
    }

    @media (max-width: 320px) {
    }
}