.contact-info {
    display: flex;
    justify-content: center;
    margin: 0 20vw;
    .left,
    .right {
        width: 50%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2::before {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        .left {
            padding: 0 0 100px 0;
        }
        .right {
            padding: 0;
        }
    }

    @media (max-width: 480px) {
    }

    @media (max-width: 320px) {
    }
}