footer {
    position: relative;
    margin-top: 80px;
    padding-top: 50px;
    border-top: 15px solid var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo-box {
        width: 25%;
        img {
            width: 100%;
        }
    }
    .logo-box:nth-child(2) {
        margin-bottom: 80px;
    }
    p {
        position: absolute;
        bottom: 0;
        font-size: 14px;
        color: #646464;
        a {
            text-decoration: none;
            color: #646464;
        }
    }
    .ancre-top {
        position: absolute;
        top: -65px;
        right: 10vw;
        height: 50px;
        width: 50px;
        background-color: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 50%;
            width: 50%;
        }
        &:hover {
            cursor: pointer;
        }
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
        p {
            font-size: 2.5vw;
        }
    }
}