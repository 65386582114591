.brands {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    gap: 10vw;
    img {
        opacity: 0.5;
        transition: 0.3s;
        &:hover {
            opacity: 1;
        }
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
    }

    @media (max-width: 768px) {
        gap: 2vw;
        height: 50px;
        margin: 0 auto;
    }

    @media (max-width: 480px) {
        
    }

    @media (max-width: 320px) {
        
    }
}