.card-work {
    position: relative;
    width: 25vw;
    height: 25vw;
    border-radius: 4px;
    background-color: #FFF;
    .img-box {
        position: absolute;
        top: 10px;
        left: 10px;
        width: calc(25vw - 20px);
        height: 75%;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    h3 {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 25%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3vw;
        text-align: center;
        padding: 10px 50px 0 50px;
    }
    .hover-filter {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
        .plus {
            height: 80px;
            width: 80px;
            border: 2px solid #FFFFFF;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: 50px;
                width: 50px;
            }
        }
    }
    &:hover .hover-filter {
        cursor: pointer;
        opacity: 1;
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
        height: 80vw;
        width: 80vw;
        .img-box {
            width: calc(80vw - 20px);
        }
        h3 {
            font-size: 5vw;
        }
    }

    @media (max-width: 768px) {
        
    }

    @media (max-width: 480px) {
        
    }

    @media (max-width: 320px) {
        
    }
}