header {
    position: relative;
    height: 50vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .title {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        display: flex;
        justify-content: center;
        align-items: center;
        h1 {
            position: relative;
            font-size: 5vw;
            &::before {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 20%;
                width: 60%;
                height: 7px;
                border-radius: 100px;
                background-color: var(--primary);
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 50vh;
        background: linear-gradient(to top, #000 10%, rgba(0, 0, 0, 0));
    }

    // Media queries pour différentes tailles d'écran

    @media (max-width: 1024px) {
        .title h1::before {
            bottom: -10px;
        }
    }

    @media (max-width: 768px) {
        
    }

    @media (max-width: 480px) {

    }

    @media (max-width: 320px) {

    }
}