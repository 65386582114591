nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 70px;
    padding: 0 60px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    .logo-box {
        height: 35px;
        img {
            height: 100%;
        }
    }
    .menu {
        display: flex;
        gap: 40px;
        a {
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 2px;
            color: #FFF;
            transition: 0.3s;
            &:hover::before,
            &.active::before {
                content: '';
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 100%;
                height: 3px;
                background-color: var(--primary);
                border-radius: 100px;
            }
        }
    }
    .burger-menu {
        display: none;
        flex-direction: column;
        cursor: pointer;
        z-index: 1001;
    }
    
    .burger-bar {
        width: 25px;
        height: 3px;
        background-color: #FFF;
        margin: 4px 0;
        border-radius: 4px;
        transition: 0.3s ease-in-out;
    }
    
    .menu.open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        z-index: 999;
    }
    
    .menu.open a {
        color: white;
        font-size: 24px;
    }

    .burger-menu.open .burger-bar:nth-child(1) {
        transform: rotate(45deg) translate(5px, 10px);
    }
    
    .burger-menu.open .burger-bar:nth-child(2) {
        opacity: 0;
    }
    
    .burger-menu.open .burger-bar:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -10px);
    }
    
    @media (max-width: 1024px) {
        .menu {
            display: none;
        }
    
        .menu.open {
            display: flex;
        }
    
        .burger-menu {
            display: flex;
        }
    }
}